@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    background: rgb(1,24,27);
    background: -moz-linear-gradient(343deg, rgba(1,24,27,1) 0%, rgba(8,60,66,1) 100%);
    background: -webkit-linear-gradient(343deg, rgba(1,24,27,1) 0%, rgba(8,60,66,1) 100%);
    background: linear-gradient(343deg, rgba(1,24,27,1) 0%, rgba(8,60,66,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01181b",endColorstr="#083c42",GradientType=1);
    font-family: "Noto Sans Thai", sans-serif;
    height: 100vh;
}

.container{
    max-width: 440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    margin-top: 2.5rem;
}

.delete-account-wrapper{
    padding: 15px;
    width: 100%;
    background: #01262a;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.account-wrapper{
    padding: 15px;
    width: 100%;
    background: #01262a;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    gap: 10px;
}

.account-wrapper span{
    color: #fff;
    font-size: 14px;
}

.account-info{
    /* display: flex;
    align-items: center; */
    text-align: center;
}

.state-info{
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    margin-top: 1.5rem;
}
.back-btn{
    background-color: #ffbb4e;
    color: #fff;
    padding: 7px 15px;
    border: 0;
    border-radius: 7px;
    font-family: "Noto Sans Thai", sans-serif;
    font-weight: 600;
    align-items: center;
    gap: 10px;
    margin-left: auto;
    font-size: 14px;
    text-decoration: none !important;
    display: flex;
    margin-bottom: 0.7rem;
    width: fit-content;
}

.text-white{
    color: #fff;
}

.text-center{
    text-align: center;
}

.logout-btn{
    background-color: #ffffff;
    color: #0557ef;
    padding: 7px 15px;
    border: 0;
    border-radius: 7px;
    font-family: "Noto Sans Thai", sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.facebook-btn{
    background-color: #ffffff;
    color: #0557ef;
    padding: 7px 15px;
    border: 0;
    border-radius: 7px;
    font-family: "Noto Sans Thai", sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
    font-size: 14px;
}

.facebook-btn img{
    width: 25px;
}

.delete-account-btn{
    background-color: #ffffff;
    color: #0557ef;
    padding: 7px 15px;
    border: 0;
    border-radius: 7px;
    font-family: "Noto Sans Thai", sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    text-decoration: none !important;
}

.delete-account-btn img{
    width: 18px;
}


.web-title{
    text-align: center;
    color: #fff;
    margin-bottom: 2.5rem;
}

.ox-wrapper{
    display: flex;
    gap: 10px;
    margin-top: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ox-wrapper .ox-selector{
    width: 31.1%;
    height: 123px;
    background-color: #055c65;
    border-radius: 5px;
    border: 0;
    outline: none !important;
    transition: 0.15s;
    position: relative;
}

.ox-wrapper .ox-selector:hover{
   transform: scale(1.03);
   cursor: pointer;
}

.ox-wrapper .ox-selector img{
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}

.ox-selector.winning {
    background-color: #55b741;
    animation: blink 1s infinite;
}

@keyframes blink {
    0%, 100% { background-color: #55b741; }
    50% { background-color: #a0eb92; }
}


.ox-main{
    position: relative;
}

.d-none{
    display: none !important;
}

.winner{
    text-align: center;
    color: #ffbb4e;
    margin-top: 1.5rem;
    margin-bottom: 0;
    height: 29px;
    font-family: "Noto Sans Thai", sans-serif;
}

.reset-game{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #dc4f08;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    border: 0;
    border-radius: 7px;
    outline: none !important;
    font-family: "Noto Sans Thai", sans-serif;
    font-weight: 800;
}

.delete-account-wrapper p{
    color: #fff;
    font-size: 14px;
    margin-bottom: 10px;
    font-family: "Noto Sans Thai", sans-serif;
}

.delete-account-wrapper ul{
    padding-left: 25px;
}

.confirm-delete-account-btn{
    width: 100%;
    padding: 7px 0;
    background-color: #dc1608;
    color: #fff;
    font-size: 14px;
    font-family: "Noto Sans Thai", sans-serif;
    font-weight: 700;
    border: 0;
    outline: none !important;
    border-radius: 5px;
    margin-top: 1rem;
    cursor: pointer;
}

.policy-page{
    color: #fff;
}

.policy-page .container{
    max-width: 1120px;
}

.privacy-policy-link{
    color: #fff;
    display: flex;
    margin-left: auto;
    margin-top: 2rem;
    width: fit-content;
}

.players-wrapper{
    padding: 15px;
    width: 100%;
    background: #01262a;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    gap: 10px;
    margin-top: 1.5rem;
}

.players-wrapper table{
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
}

.players-wrapper table thead{
    font-size: 14px;
}

.players-wrapper table thead th{
    padding: 5px 5px;
}

.players-wrapper table tbody td{
    padding: 5px 5px;
    text-align: center;
    background-color: #01262a;
    color: #fff;
    font-size: 14px;
}

.pagination{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 1rem;
}

.pagination button{
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 0;
    background-color: #fff;
    color: #000;
}

.pagination button.active{
    background-color: #dc4f08;
    color: #fff;
}

.account-btns{
    display: flex;
    justify-content: space-around;
    gap: 10px;
    margin-top: 1rem;
}

.account-btn{
    background-color: #ffffff;
    color: #0557ef;
    padding: 7px 15px;
    border: 0;
    border-radius: 7px;
    font-family: "Noto Sans Thai", sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    text-decoration: none !important;
}

.account-btn img{
    width: 20px;
}
